<template>
    <div class="detail">
        <div class="banner-box">
            <div class="banner">
                <el-carousel indicator-position="outside" arrow="always" height="442rem">
                    <el-carousel-item class="carousel-item">
                        <img src="./images/1.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/2.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/3.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/4.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/5.jpg"/>
                    </el-carousel-item>
                    <el-carousel-item class="carousel-item">
                        <img src="./images/6.jpg"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="info">
                <div class="title">快麦E31G系列云标签机</div>
                <div class="sub-title">4G+手动撕纸</div>
                <div class="text">通讯接口：4G</div>
                <div class="text">打印速度：150MM/S</div>
                <div class="text">打印宽度：40-72MM</div>
                <div class="text">适用：电商、物流等打印标签</div>
                <!--                <div class="price">官网零售价：¥ 498起</div>-->
                <div
                    class="btn"
                    style="display:none;"
                    @click="
                        openTaobao(
                            'https://detail.tmall.com/item.htm?spm=a1z10.3-b-s.w4011-21228571373.94.1c456263SV0E2F&id=597836071265&rn=059417eeb73f7f2aaae5113831f29876&abbucket=14&skuId=4365401269663'
                        )
                    "
                >
                    了解更多
                </div>
            </div>
        </div>
        <div class="content-bg1"></div>
        <div class="content-bg2"></div>
        <div class="content-bg3"></div>
        <div class="content-bg4"></div>
        <div class="content-bg5"></div>
        <div class="content-bg6"></div>
        <div class="content-bg7"></div>
        <div class="content-bg8"></div>
        <div class="content-bg9"></div>
        <div class="content-bg10"></div>
        <div class="content-bg11"></div>
        <div class="content-bg12"></div>
        <div class="content-bg13"></div>
    </div>
</template>

<script>
export default {
    name: "E31G",
    data() {
        return {};
    },
    methods: {
        openTaobao(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
.detail {
    padding-top: 172rem;

    .banner-box {
        padding: 32rem 0;
        width: 1300rem;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .banner {
            width: 784rem;
            height: 500rem;

            .carousel-item {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img,
            video {
                height: 442rem;
            }
        }

        .info {
            width: 30%;

            .title {
                margin-top: 64rem;
                font-size: 22rem;
                font-weight: 900;
            }

            .sub-title {
                margin: 32rem 0 64rem 0;
                font-size: 16rem;
            }

            .text {
                margin-top: 8rem;
                font-size: 14rem;
            }

            .price {
                margin-top: 48rem;
                font-size: 22rem;
                font-weight: 900;
            }

            .btn {
                display: inline-block;
                padding: 12rem 16rem;
                margin-top: 24rem;
                font-size: 16rem;
                background: #ff6932;
                border-radius: 12rem;
                color: #fff;
                cursor: pointer;
            }
        }
    }

    .content-bg1 {
        height: 2010rem;
        background: url("./images/1920-1076_01.jpg") center / 1920rem no-repeat;
    }

    .content-bg2 {
        height: 1981rem;
        background: url("./images/1920-1076_02.jpg") center / 1920rem no-repeat;
    }

    .content-bg3 {
        height: 1805rem;
        background: url("./images/1920-1076_03.jpg") center / 1920rem no-repeat;
    }

    .content-bg4 {
        height: 1763rem;
        background: url("./images/1920-1076_04.jpg") center / 1920rem no-repeat;
    }

    .content-bg5 {
        height: 2117rem;
        background: url("./images/1920-1076_05.jpg") center / 1920rem no-repeat;
    }

    .content-bg6 {
        height: 2007rem;
        background: url("./images/1920-1076_06.jpg") center / 1920rem no-repeat;
    }

    .content-bg7 {
        height: 2390rem;
        background: url("./images/1920-1076_07.jpg") center / 1920rem no-repeat;
    }

    .content-bg8 {
        height: 1713rem;
        background: url("./images/1920-1076_08.jpg") center / 1920rem no-repeat;
    }

    .content-bg9 {
        height: 1320rem;
        background: url("./images/1920-1076_09.jpg") center / 1920rem no-repeat;
    }

    .content-bg10 {
        height: 1474rem;
        background: url("./images/1920-1076_10.jpg") center / 1920rem no-repeat;
    }

    .content-bg11 {
        height: 1239rem;
        background: url("./images/1920-1076_11.jpg") center / 1920rem no-repeat;
    }

    .content-bg12 {
        height: 1181rem;
        background: url("./images/1920-1076_12.jpg") center / 1920rem no-repeat;
    }

    .content-bg13 {
        height: 2183rem;
        background: url("./images/1920-1076_13.jpg") center / 1920rem no-repeat;
    }
}
</style>
