<template>
    <div id="app">
        <Header></Header>
        <router-view></router-view>
        <Footer></Footer>
        <KeFu></KeFu>
    </div>
</template>

<script>
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import KeFu from './components/KeFu/KeFu';

export default {
    name: "App",
    components: {
        Header,
        Footer,
        KeFu,
    },
    mounted() {
        document.title = "快麦云打印官网";
        this.$router.afterEach(() => {
            window.scroll(0, 0);
        });

        function setSize() {
            let screenWidth = document.documentElement.clientWidth
            let screenHeight = document.documentElement.clientHeight
            let scaled = 1920 / 1040
            let currentFontSize = 12
            if (scaled > screenWidth / screenHeight) {
                currentFontSize = screenWidth / 1920
            } else {
                currentFontSize = screenHeight / 1040
            }

            document.documentElement.style.fontSize = currentFontSize + 'px'
        }

        /*(function (w) {
            w.addEventListener('resize', setSize)
            w.addEventListener('pageShow', setSize)
            w.addEventListener('DOMContentLoaded', setSize)
        })(window)
        setSize()*/
        document.documentElement.style.fontSize = '0.9px'
    },
};
</script>

<style lang="scss" scoped>
#app {
  min-width: 1300rem;
}

.el-main {
  padding: 0;
}
</style>
