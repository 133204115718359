<template>
    <div class="kefu_box">
        <img class="kefu_img" src="./images/kefu1.png" alt="kefu">
        <div class="kefu_list">
            <div class="kefu_item kefu_item1">
                <div class="kefu_hover_box">
                    <div class="kefu_hover_title">
                        售前咨询
                    </div>
                    <div class="kefu_hover_text">
                        <img class="kefu_icon" src="./images/kefu5.png" alt="phone">
                        咨询电话：18621377135<br>
                        （手机 / 微信同号）
                    </div>
                </div>
            </div>
            <div class="kefu_item kefu_item2">
                <div class="kefu_hover_box">
                    <div class="kefu_hover_title">
                        技术支持
                    </div>
                    <div class="kefu_hover_text">
                        <img class="kefu_icon" src="./images/kefu5.png" alt="phone">
                        咨询电话：18969120021<br>
                        （手机 / 微信同号）
                    </div>
                </div>
            </div>
            <div class="kefu_item kefu_item3" @click="openLink"></div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        openLink() {
            window.open('https://admin.qidian.qq.com/template/blue/mp/menu/qr-code-jump.html?linkType=0&env=ol&kfuin=2852158633&fid=673&key=2a418433d67e5f6e5dc776b68b0fc512&cate=1&source=&isLBS=&isCustomEntry=&type=16&ftype=1&_type=wpa&qidian=true')
        }
    }
}
</script>

<style scoped lang="scss">
.kefu_box {
    position: fixed;
    top: 151rem;
    right: 12rem;
    width: 68rem;
    height: 277rem;
    z-index: 999;

    .kefu_img {
        width: 68rem;
        height: 77rem;
    }

    .kefu_list {
        margin-top: 8rem;
        padding: 12rem 4rem;
        width: 68rem;
        background: #191938;
        box-shadow: 0 4rem 10rem 0 rgba(0, 0, 0, 0.302);
        border-radius: 35rem;

        .kefu_item {
            position: relative;
            box-sizing: border-box;
            height: 70rem;
            padding-top: 44rem;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 300;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            cursor: pointer;

            &.kefu_item1 {
                background: url("./images/kefu2.png") center / 48rem no-repeat;
            }

            &.kefu_item2 {
                background: url("./images/kefu3.png") center / 48rem no-repeat;
            }

            &.kefu_item3 {
                background: url("./images/kefu4.png") center / 48rem no-repeat;
            }

            &:not(:last-child) {
                border-bottom: 1rem dashed #41415D;
            }

            &:hover {
                color: #FD6520;

                &.kefu_item1 {
                    background: url("./images/kefu2_h.png") center / 48rem no-repeat;
                }

                &.kefu_item2 {
                    background: url("./images/kefu3_h.png") center / 48rem no-repeat;
                }

                &.kefu_item3 {
                    background: url("./images/kefu4_h.png") center / 48rem no-repeat;
                }

                .kefu_hover_box {
                    display: flex;
                }
            }

            .kefu_hover_box {
                display: none;
                box-sizing: border-box;
                position: absolute;
                top: -40rem;
                right: 88rem;
                padding: 0 40rem;
                width: 322rem;
                height: 205rem;
                background: #FFFFFF;
                box-shadow: 0 4rem 10rem 0 rgba(0, 0, 0, 0.302);
                border-radius: 20rem;
                border: 1rem solid #DDDDDD;
                flex-direction: column;
                align-items: center;

                &::before {
                    content: '';
                    position: absolute;
                    top: 30%;
                    right: -6rem;
                    border-left: 16rem solid #fff;
                    border-top: 16rem solid transparent;
                    border-bottom: 16rem solid transparent;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: -20rem;
                    top: 0;
                    width: 20rem;
                    height: 100%;
                    //background: #ff0000;
                }

                .kefu_hover_title {
                    margin-top: 12rem;
                    width: 100%;
                    height: 77rem;
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 300;
                    font-size: 24px;
                    color: #323233;
                    border-bottom: 1rem dashed #DDDDDD;
                    line-height: 77rem;
                }

                .kefu_hover_text {
                    margin-top: 16rem;
                    display: flex;
                    flex-direction: row;
                    line-height: 24rem;
                    font-family: SourceHanSansCN, SourceHanSansCN;
                    font-weight: 300;
                    font-size: 14px;
                    color: #323233;

                    .kefu_icon {
                        margin-right: 12rem;
                        width: 25rem;
                        height: 25rem;
                    }
                }
            }
        }
    }
}
</style>
