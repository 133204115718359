<template>
    <div class="footer">
        <div class="footer_container">
            <div class="main_content footer_row">
                <div class="footer_col">
                    <div class="footer_col_title">快麦云打印机</div>
                    <div class="footer_col_text" @click="$router.push('/productCenter/WiFi/E41W')">
                        E41W云标签打印机
                    </div>
                    <div class="footer_col_text">E20W云面单机</div>
                    <div class="footer_col_text">DP158云小票打印机</div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">快麦产品与解决方案</div>
                    <!--                    <div class="footer_col_text" @click="openNew('https://gj.fanqie365.cn/')">番茄打印管家</div>-->
                    <div class="footer_col_text" @click="openNew('https://dyj.kuaimai.com/')">快麦打印机</div>
                    <div class="footer_col_text" @click="openNew('https://fanqie365.cn/')">番茄标签</div>
                    <div class="footer_col_text" @click="openNew('https://solution.kuaimai.com/#/home ')">番茄打印解决方案</div>
                    <div class="footer_col_text" @click="$router.push('/about')">关于我们</div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">售后客服</div>
                    <div class="footer_col_phone">400-007-5533</div>
                    <div class="footer_col_text">工作时间：周一至周日 9:00 - 21：00</div>
                </div>
                <div class="footer_col">
                    <div class="footer_col_title">技术支持</div>
                    <img class="footer_qrcode" src="./images/qrcode.png" alt="qrcode"/>
                </div>
                <div class="footer_col">
                    <img class="footer_logo" src="./images/footerLogo.svg" alt="logo"/>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="main_content">
                杭州淘云科技有限公司 版权所有 浙ICP备09083305号 浙公网安备 33010802003954号
            </div>
        </div>

        <div class="footer_fix" v-if="false">
            <div class="main_content">
                <div class="footer_fix_title">快麦云打印机 免驱动安装，异地远程、多人跨端打印</div>
                <br>
                <div class="footer_fix_text">售前专属电话：18621377135 (微信同号)</div>
                <div class="footer_fix_text">技术对接咨询：18969120021 (微信同号)</div>
                <div class="footer_fix_text">杭州淘云科技有限公司 版权所有 浙ICP备09083305号 浙公网安备
                    33010802003954号
                </div>
                <a class="footer_fix_btn" target="_blank"
                   href="https://www7c1.53kf.com/webCompany.php?kf_sign=jkwODMTY5Ng3OTEzMzcyNTQwNTE1MDAyNzI4Nzg0MDE=&arg=10878401&style=1&guest_id=15967500498003&language=cn&charset=GBK&referer=https%3A%2F%2Fopen.iot.kuaimai.com%2F%23%2Fhome&keyword=&tpl=crystal_blue&uid=2feedcb0888fc84e80fc24a68917e311&is_group=&tfrom=1&timeStamp=1696930019845&ucust_id=">咨询在线客服</a>
                <a class="footer_fix_btn footer_fix_btn2" target="_blank"
                   href="https://ding.cjfx.cn/f/1p6kmzkx">免费样机领取</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
        openNew(url) {
            window.open(url);
        },
    },
};
</script>

<style lang="scss" scoped>
/* 底部样式 */
.footer {
  //padding-bottom: 200rem;

  .footer_container {
    height: 274rem;
    background: #262644;

    .footer_row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .footer_col {
        margin-top: 60rem;

        .footer_col_title {
          margin-bottom: 13rem;
          line-height: 26rem;
          font-size: 18rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FD6520;
        }

        .footer_col_text {
          margin-bottom: 13rem;
          line-height: 20rem;
          font-size: 14rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }

        .footer_col_phone {
          margin: 33rem 0 22rem 0;
          font-size: 36rem;
          font-family: Alibaba Sans-Regular, Alibaba Sans;
          font-weight: 400;
          color: #FD6520;
          line-height: 28rem;
        }

        .footer_qrcode {
          width: 106rem;
        }

        .footer_logo {
          margin-top: 55rem;
          width: 229rem;
        }
      }
    }
  }

  .footer_bottom {
    height: 60rem;
    line-height: 60rem;
    font-size: 14rem;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    background: #191938;
  }

  .footer_fix {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200rem;
    background: #262644;
    color: #FFFFFF;
    z-index: 2;

    .footer_fix_title {
      padding: 32rem 0 0 0;
      font-size: 26rem;
      line-height: 36rem;
    }

    .footer_fix_text {
      font-size: 20rem;
      line-height: 28rem;
      font-weight: 400;
    }

    .footer_fix_btn {
      position: absolute;
      top: 64rem;
      right: 200rem;
      background: linear-gradient(90deg, #1A73E8 0%, #19AEFF 100%);
      border-radius: 22rem;
      height: 44rem;
      width: 158rem;
      cursor: pointer;
      text-align: center;
      line-height: 44rem;
      color: #fff;
    }

    .footer_fix_btn2 {
      top: 128rem;
    }
  }
}
</style>
